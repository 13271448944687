import { AiOutlineRight } from 'react-icons/ai'
import { IOutputData } from '../../models/IOutputData'
import bwtIcon from '../../assets/BWT_Logo_rgb.svg'
import { GeneralTexts } from '../../language/Texts'

interface ShareProps {
    component: IOutputData
}

function CustomerPortal({ component }: ShareProps) {

    return (
        <a className="customer_portal share" href='https://achema.bwt-aqua-portal.com' target='_blank'>
            <div className="share_text">
                <div className="share_title">{GeneralTexts.bwtCustomerPortal}</div>
                <div className="share_section_icon"><AiOutlineRight /></div>
            </div>
            <div className="analyse_icon_wrapper">
                <img src={bwtIcon} className='analyse_icon' />
            </div>
        </a>
    )
}

export default CustomerPortal
