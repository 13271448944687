import { useState } from 'react';
import ButtonGroup from '../../components/actionGroup/ActionGroup';
import ComponentAtrributes from '../../components/componentAttributes/ComponentAtrributes';
import Contact from '../../components/contact/Contact';
import CustomModal from '../../components/customModal/CustomModal';
import DocumentsOnDelievery from '../../components/documentsOnDelievery/DocumentsOnDelievery';
import Footer from '../../components/footer/Footer';
import General from '../../components/general/General';
import Header from '../../components/header/Header';
import ProductAttributes from '../../components/productAttributes/ProductAttributes';
import ProductDocuments from '../../components/productDocuments/ProductDocuments';
import ProductSnapshot from '../../components/productSnapshot/ProductSnapshot';
import { IOutputData } from '../../models/IOutputData';
import './Main.scss';
import ActionGroup from '../../components/actionGroup/ActionGroup';

interface MainProps {
    component: IOutputData,
    language: string,
    setLanguage: (lang: string) => void
}

function Main({ component, language, setLanguage }: MainProps) {
    const [isModalOpen, setIsModalOpen] = useState(!sessionStorage.getItem("hasEmailRegistered") ?? true);
    const toggleModal = () => {
        setIsModalOpen(false);
    }

    return (<div className='main-wrapper'>
        <div className='main_wrapper' data-testid="main" >
            <div className="first_page_wrapper">
                <Header
                    language={language}
                    setLanguage={setLanguage}
                />
                <ProductSnapshot
                    component={component}
                />
            </div>
            <ActionGroup component={component} />
            <General
                articleDescriptionS={component.articleDescriptionS}
                generalAttributeList={component.generalAttributesList} />
            <ProductAttributes attributes={component.productAttributesList} />
            <ComponentAtrributes attributes={component.manufacturerAttributesList} />
            <ProductDocuments documentList={component.productDocuments} />
            <DocumentsOnDelievery documentList={component.componentDocuments} />
            <Contact
                articleDescriptionS={component.articleDescriptionS}
                generalAttributeList={component.generalAttributesList} />
            <Footer />
            <CustomModal
                toggleModal={toggleModal}
                isModalOpen={isModalOpen}
            />
        </div>
    </div>
    )
}

export default Main
