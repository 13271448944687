import { AiOutlineRight } from 'react-icons/ai'
import analyseIcon from '../../assets/analyse.svg'
import { IOutputData } from '../../models/IOutputData'
import { GeneralTexts } from '../../language/Texts'

interface ShareProps {
    component: IOutputData
}

function Analyse({ component }: ShareProps) {

    return (
        <a className="share" href='https://app.streametric.io/login/bwt' target='_blank'>
            <div className="share_text">
                <div className="share_title">{GeneralTexts.aquaAnalyse}</div>
                <div className="share_section_icon"><AiOutlineRight /></div>
            </div>
            <div className="analyse_icon_wrapper">
                <img src={analyseIcon} className='analyse_icon' />
            </div>
        </a>
    )
}

export default Analyse
