import IResponse from '../models/IResponse';
import version from '../version.json';

export class HttpRequests {
    async requestComponent(language: string): Promise<IResponse | null> {
        const requestURL = this.getRequestUrl('getcomponent');
        const requestData = this.getRequestData(language);

        try {
            const response = await fetch(requestURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const result = await this.sendResponseWithVersion(response);
            return result;
        } catch (error) {
            console.error('Error on getting component', error);
            throw error;
        }
    }

    async registerMail(mail: string): Promise<IResponse | null> {
        const requestURL = this.getRequestUrl('register-mail');
        const requestData = this.getRequestData(null, mail);

        try {
            const response = await fetch(requestURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const result = await this.sendResponseWithVersion(response);
            return result;
        } catch (error) {
            console.error('Error on getting component', error);
            throw error;
        }
    }


    private getRequestUrl(path: string): string {
        if (process.env.NODE_ENV === 'development') {
            // return `http://192.168.178.24:8080/api/${path}`;
            return `http://localhost:8080/api/${path}`;
        } else {
            return `/api/${path}`;
        }
    }

    private async sendResponseWithVersion(response: Response): Promise<IResponse> {
        const versionHeader = response.headers.get('Webview-Backend-Version') ?? null;
        const responseData = await response.json();

        if (versionHeader) {
            responseData.backendVersion = versionHeader;
        }

        return responseData;
    }

    private getRequestData = (language: string | null, email?: string) => {
        const requestData = {
            url: window.location.href,
            type: 'bwt',
            additional: {
                frontendVersion: version.version,
                displayResolution: `${window.screen.width}x${window.screen.height}`,
            },
        };
        if (language) {
            requestData['language'] = language;
        }
        if (email) {
            requestData['email'] = email;
        }
        return requestData;
    };
}
