import { GeneralTexts } from '../../language/Texts';
import { EmailBodyType } from '../../models/EmailBodyType.enum';
import { IOutputData } from '../../models/IOutputData';
import { getMailBody, openEmailSender } from '../../utils/EmailSender';
import testIcon from '../../assets/a.png';
import './ProductSnapshot.scss';

interface SnapshotProps {
    component: IOutputData
}

function ProductSnapshot({ component }: SnapshotProps) {
    const mailSubject = `${component.articleDescriptionS} - ${GeneralTexts.digitalTagPlate} | ${component.generalAttributesList.componentType}`
    const mailBody = getMailBody(component.generalAttributesList, EmailBodyType.CONTACT, component.articleDescriptionS)

    const shareHandler = async () => {
        const shareData = {
            title: component.articleDescriptionS,
            text: component.articleDescriptionS,
            url: window.location.href,
        };

        try {
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                openEmailSender(mailSubject, mailBody, true)
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    }

    return (
        <div className='snapshot_wrapper'>
            <div className="title_text_wrapper">
                <div className="component_title">
                    {component.articleDescriptionS}
                </div>
                <div className="component_order_number">
                    {component.generalAttributesList.articleNumberExt && component.generalAttributesList.articleNumberExt}
                </div>
            </div>
            <div className="icon_wrapper">
                <img
                    className='component_icon'
                    src={component.icon}
                    alt="component_icon" />
            </div>
        </div>
    )
}

export default ProductSnapshot

