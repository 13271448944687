import { AiOutlineRight } from 'react-icons/ai'
import { MdShare } from 'react-icons/md'
import { GeneralTexts } from '../../language/Texts'
import { EmailBodyType } from '../../models/EmailBodyType.enum'
import { IOutputData } from '../../models/IOutputData'
import { getMailBody, openEmailSender } from '../../utils/EmailSender'

interface ShareProps {
    component: IOutputData
}

function Share({ component }: ShareProps) {
    const mailSubject = `${component.articleDescriptionS} - ${GeneralTexts.digitalTagPlate} | ${component.generalAttributesList.componentType}`
    const mailBody = getMailBody(component.generalAttributesList, EmailBodyType.CONTACT, component.articleDescriptionS)


    const shareHandler = async () => {
        const shareData = {
            title: component.articleDescriptionS,
            text: component.articleDescriptionS,
            url: window.location.href,
        };

        try {
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                openEmailSender(mailSubject, mailBody, true)
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    }

    return (
        <div className="share" onClick={() => shareHandler()}>
            <div className="share_text">
                <div className="share_title">{GeneralTexts.shareProduct}</div>
                <div className="share_section_icon"><AiOutlineRight /></div>
            </div>
            <div className="share_icon_wrapper">
                <MdShare className='share_icon' />
            </div>
        </div>
    )
}

export default Share
