import { IOutputData } from "../../models/IOutputData"
import Analyse from "./Analyse"
import './ActionGroup.scss'
import CustomerPortal from "./CustomerPortal"
import Share from "./Share"

interface ContactProps {
    component: IOutputData
}

function ActionGroup({ component }: ContactProps) {

    return (
        <div className="action_group">
            <CustomerPortal component={component} />
            <Analyse component={component} />
            <Share component={component} />
        </div>
    )
}

export default ActionGroup
