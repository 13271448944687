import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { GeneralTexts } from '../../language/Texts';
import { HttpRequests } from '../../utils/HttpRequests';
import "./EmailForm.scss";


interface IEmailFormProps {
    toggleModal: () => void,
}

function EmailForm({ toggleModal }: IEmailFormProps) {
    const [email, setEmail] = useState('')

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await new HttpRequests().registerMail(email)
            toggleModal()
        } catch (error) {
            toggleModal()
            console.log('error on mail registraition');
        }
        sessionStorage.setItem('hasEmailRegistered', email)
        setEmail('')
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    return (
        <>
            <form data-testid="form" onSubmit={handleSubmit} className='email_form'>
                <div className="form_group">
                    <input
                        type="email"
                        placeholder={GeneralTexts.email}
                        name="email"
                        className="email_input"
                        onChange={(e) => handleEmailChange(e)}
                        value={email}
                        required
                    />
                    <Button
                        data-testid="submit_button"
                        type="submit"
                        className="email_form_button"
                    >
                        {GeneralTexts.modalButton}
                    </Button>
                </div>
            </form>
        </>

    )
}

export default EmailForm
