import Modal from 'react-bootstrap/Modal';
import EmailForm from '../emailForm/EmailForm';
import './CustomModal.scss';
import bwtLogo from '../../assets/BWT_Logo_rgb-old.svg'
import { GeneralTexts } from '../../language/Texts';

function CustomModal({ toggleModal, isModalOpen }) {

    return (
        <>
            <Modal show={isModalOpen} centered dialogClassName="custom-modal">
                <Modal.Body className='modal_header' >
                    <img className='modal_logo' src={bwtLogo} alt="bwt-logo" />
                    <span className='modal_text'> {GeneralTexts.modalHeader}</span>
                </Modal.Body>
                <Modal.Footer className='modal_footer'><EmailForm toggleModal={toggleModal} /> </Modal.Footer>
            </Modal >
            {isModalOpen && <div className="backdrop-blur" />
            }
        </>
    );
}
export default CustomModal
