import i18n from './i18n';

export class GeneralTexts {
    private constructor() { }
    static get tid() { return i18n.t(`tid.${i18n.language}`) }
    static get articleNumberExt() { return i18n.t(`articleNumberExt.${i18n.language}`) }
    static get batchNumber() { return i18n.t(`batchNumber.${i18n.language}`) }
    static get componentType() { return i18n.t(`componentType.${i18n.language}`) }
    static get customerArticleNumber() { return i18n.t(`customerArticleNumber.${i18n.language}`) }
    static get customerOrderNumber() { return i18n.t(`customerOrderNumber.${i18n.language}`) }
    static get delieveryDate() { return i18n.t(`delieveryDate.${i18n.language}`) }
    static get deliveryNumber() { return i18n.t(`deliveryNumber.${i18n.language}`) }
    static get manufacturer() { return i18n.t(`manufacturer.${i18n.language}`) }
    static get orderNumber() { return i18n.t(`orderNumber.${i18n.language}`) }
    static get productInfo() { return i18n.t(`productInfo.${i18n.language}`) }
    static get productionDate() { return i18n.t(`productionDate.${i18n.language}`) }
    static get productCodeWork() { return i18n.t(`productCodeWork.${i18n.language}`) }
    static get serialNumber() { return i18n.t(`serialNumber.${i18n.language}`) }
    static get articleDesignation() { return i18n.t(`articleDesignation.${i18n.language}`) }
    static get ourOrderNumber() { return i18n.t(`ourOrderNumber.${i18n.language}`) }
    static get orderConfirmation() { return i18n.t(`orderConfirmation.${i18n.language}`) }
    static get dateOfManufacture() { return i18n.t(`dateOfManufacture.${i18n.language}`) }
    static get type() { return i18n.t(`type.${i18n.language}`) }

    static get generalSectionTitle() { return i18n.t(`generalSectionTitle.${i18n.language}`) }
    static get productAttributes() { return i18n.t(`productAttributes.${i18n.language}`) }
    static get componentAttributes() { return i18n.t(`componentAttributes.${i18n.language}`) }
    static get certificates() { return i18n.t(`certificates.${i18n.language}`) }
    static get currentProductDocumentation() { return i18n.t(`currentProductDocumentation.${i18n.language}`) }
    static get documentationUponDelivery() { return i18n.t(`documentationUponDelivery.${i18n.language}`) }

    static get connectWithUs() { return i18n.t(`connectWithUs.${i18n.language}`) }
    static get termOfUse() { return i18n.t(`termOfUse.${i18n.language}`) }
    static get dataSecurity() { return i18n.t(`dataSecurity.${i18n.language}`) }
    static get imprint() { return i18n.t(`imprint.${i18n.language}`) }

    static get reorder() { return i18n.t(`reorder.${i18n.language}`) }
    static get digitalTagPlate() { return i18n.t(`digitalTagPlate.${i18n.language}`) }
    static get request() { return i18n.t(`request.${i18n.language}`) }

    static get modalHeader() { return i18n.t(`modalHeader.${i18n.language}`) }
    static get modalButton() { return i18n.t(`modalButton.${i18n.language}`) }
    static get email() { return i18n.t(`email.${i18n.language}`) }
    static get bwtCustomerPortal() { return i18n.t(`bwtCustomerPortal.${i18n.language}`) }
    static get aquaAnalyse() { return i18n.t(`aquaAnalyse.${i18n.language}`) }
    static get shareProduct() { return i18n.t(`shareProduct.${i18n.language}`) }
}

export class OtherTexts {
    constructor() { }
    static get openLink() { return i18n.t(`openLink.${i18n.language}`) }
    static get sendEvalutation() { return i18n.t(`sendEvalutation.${i18n.language}`) }
    static get thanksForRating() { return i18n.t(`thanksForRating.${i18n.language}`) }
    static get linkToWebView() { return i18n.t(`linkToWebView.${i18n.language}`) }
    static get bwtWebsite() { return i18n.t(`bwtWebsite.${i18n.language}`) }
    static get imprintUrl() { return i18n.t(`imprintUrl.${i18n.language}`) }
    static get worksCertificate() { return i18n.t(`worksCertificate.${i18n.language}`) }
}